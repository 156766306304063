<template>
  <div>
    <div>
      <video autoplay :src="video_src" ref="video" style="background-color: #000;"></video>
      <canvas style="display: none;" ref="canvas"></canvas>
      <div class="mask"></div>
      <img ref="imgXX" src="" alt="">
      <!-- <input v-show="false" class="card_input" v-on:change="appCapture" type="file" accept="image/*" capture="camera" /> -->
    </div>
    <div class="btn1" v-show="step === 0" @click="start()">采集</div>
    <div class="btn1" v-show="step === 1" @click="takePhoto()">暂停</div>
    <!-- <div class="tips">
      <div>左脸({{face[0]}}/5)</div>
      <div>中脸({{face[1]}}/5)</div>
      <div>右脸({{face[2]}}/5)</div>
    </div> -->
    <div class="tips" style="display: block;" v-show="step === 0">
      <div>请保持水平位置</div>
      <div>请将单一头猪置于识别框中</div>
    </div>
    <div class="tips" style="display: block;" v-show="step === 1">
      <div>采集计算中</div>
    </div>
    <div class="upload" v-show="show_upload">
      <div class="header">正在上传资料，请稍等...</div>
      <div>
        <div class="download">{{download}}kb/1447kb</div>
        <div class="progress">
          <el-progress :percentage="progress"></el-progress>
        </div>
        <div class="speed">上传速度:{{speed}}kb</div>
      </div>
      
    </div>
    <!-- <el-button type="primary">111</el-button> -->
  </div>
</template>
<script>
export default{
  data() {
    return {
      cvs:{},
      video:{},
      video_src:'',
      ctx:{},
      mediaStreamTrack:{},
      constraints:{},
      step:0,
      face:[0,0,0],
      download:0,
      speed:665,
      progress:0,
      show_upload:false,
    }
  },
  mounted(){
    window.URL = (window.URL || window.webkitURL || window.mozURL || window.msURL);

    let width = document.body.offsetWidth

    this.cvs = this.$refs.canvas
    this.video = this.$refs.video
    this.cvs.width = this.video.width = width
    this.cvs.height = this.video.height = 400
    // const { width,height } = this.cvs
    this.ctx = this.cvs.getContext('2d')
    
    const constraints = {
        auto:true,
        video:{
            facingMode: "environment" ,
            width:width,
            height:400,
            
        }
    }
    this.constraints = constraints

    if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
    }

    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function(constraints) {
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
        if (!getUserMedia) {
            return Promise.reject(new Error('当前设备摄像头无法调用'));
        }
        return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
        });
      }
    }

    this.openMedia()

    // navigator.mediaDevices.getUserMedia(constraints).then(stream=>{
    //     // console.log('stream',stream)
    //     // this.video_src = require(stream)
    //     this.video.srcObject = stream
    //     this.video.onloadedmetadata = ()=> this.video.play()
    // })
  },
  methods:{
    openMedia(){
      navigator.mediaDevices.getUserMedia(this.constraints).then(this.successFunc).catch(this.errorFunc);
    },

    successFunc(stream) {
      // console.log('stream',stream)
      this.mediaStreamTrack = stream
      // this.video = document.querySelector('.video')
      if ("srcObject" in this.video) {
          this.video.srcObject = stream
      } else {
          this.video.src = window.URL && window.URL.createObjectURL(stream) || stream
      }
      this.video.play()
    },

    errorFunc(err) {
        alert('摄像头唤起失败')
        alert(err)
    },
    start(){
      this.step = 1
      let time2 = {}
      let time3 = {}
      let time1 = setInterval(() => {
        this.face[0]++
        this.$forceUpdate()
        if(this.face[0]>=5){
          clearInterval(time1)
          this.finish()
        }
      }, 500);
      setTimeout(() => {
        time2 = setInterval(() => {
          this.face[1]++
          this.$forceUpdate()
          if(this.face[1]>=5){
            clearInterval(time2)
            this.finish()
            if(this.$route.query.fail == 'true'){
              setTimeout(() => {
                  this.$message.error('采集错误')
                  this.$router.push('/weight?fail=true')
              }, 3000);
            }
          }
        },  Math.floor(((Math.random()*10)+2))*100);
      }, 1000);

      setTimeout(() => {
        time3 = setInterval(() => {
          this.face[2]++
          this.$forceUpdate()
          let count = 0
          if(this.$route.query.fail == 'true'){
            count = 2
          }else{
            count = 5
          }
          if(this.face[2]>=count){
            clearInterval(time3)
            this.finish()
          }
        }, Math.floor(((Math.random()*10)+2))*100);
      }, 500);
      // console.log()
    },
    finish(){
      for(let i = 0; i < this.face.length; i++) {
        if(this.face[i]<5){
          return
        }
      }
      // this.face.forEach(val => {
      //   if(val<5){
      //     return
      //   }
      // });
      this.show_upload = true
      let time = setInterval(() => {
        
        if(this.download+this.speed<1447){
          this.download+=this.speed
          this.progress = Math.floor(this.download/1447*100)
        }else{
          this.download = 1447
          this.progress = 100
          clearInterval(time)
          setTimeout(() => {
            this.$router.push({
              path:'/weight'
            })
          }, 500);
        }
      }, 1000);

    },
    takePhoto(){
      this.video.pause()
    }
  }
}
</script>
<style lang="stylus" scoped>
  .btn1{
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 50%;
    background-color: #409EFF;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
  }

  .mask{
    width: 60vw;
    height: 230px;
    position: absolute;
    top: 85px;
    left: 20vw;
    box-shadow: rgba(0,0,0,.6) 0  0  0  85px;
    z-index: 1;

  }

  .tips{
    color: red;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }

  .upload{
    position: absolute;
    background-color: #fff;
    top: 0;
    z-index: 2;
    width: 80%;
    top: 30vh;
    left: 10%;
    height: 120px;
    border-radius 5px;
    .header{
      margin-top 20px;
      margin-bottom 10px;
      color #aaa;
    }
    .download{
      font-size 14px;
      text-align right ;
      padding-right 20px;
    }
    .speed{
      font-size 14px;
      text-align left;
      padding-left 10px;
    }
  }

</style>